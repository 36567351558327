@media (min-width: 600px) {
    .ac-loader-wrapper.ac-desktop-page {
        height: 100vh;
        flex-direction: row-reverse;
    }
    .ac-desktop-page .ac-btn span {
        font-size: 69%;
        left: -1vw;
    }
    .ac-desktop-page p {
        margin-bottom: 1vw;
        font-size: 1vw;
        text-align: center;
        font-family: "Spoiler-Regular";
    }
    .ac-desktop-page p strong {
        font-size: 1.7vw;
    }
    .ac-desktop-page .ac-logo-oreo {
        z-index: 9;
        width: 50%;
        margin: 0 auto;
        position: relative;
        left: unset;
        top: unset;
        transform: unset;
    }
    .ac-desktop-page .ac-logo-oreo img {
        width: 70%;
    }
    .ac-desktop-page .ac-wrapper-text-animate {
        height: 100vh;
    }
    .ac-desktop-page .ac-loader-inner-anim {
        width: 3vw;
    }
    .ac-desktop-page .ac-loader-inner-anim.ac-right {
        width: 1vw;
    }
    .ac-desktop-page .ac-loader-anim.ac-center {
        height: 90vh;
    }
    .ac-desktop-page form {
        display: flex;
        align-items: center;
        margin: auto;
        width: max-content;
    }
    .ac-desktop-page input {
        font-family: "FbSpoilerRegular";
        font-family: "FbReformaNarrow-Regular";
        width: 90%;
        display: inline-block;
        border-radius: 42px;
        border: unset;
        background-color: #fff;
        padding: 0.3vw 0.6vw;
        font-size: 1.4vw !important;
    }
    .ac-desktop-page input:first-child {
        width: 50%;
        margin-left: 0vw;
        margin-right: 2vw;
    }
    .ac-desktop-page form .ac-btn {
        font-family: "FbSpoilerRegular";
        font-family: "FbReformaNarrow-Regular";
        padding: 0.3vw 0.6vw;
        height: unset;
        font-size: 1.4vw !important;
        color: #fff;
        border: solid 1px #fff;
        background: rgb(0, 79, 163);
        background: -o-linear-gradient( left, rgba(0, 79, 163, 1) 0%, rgba(0, 177, 238, 1) 50%, rgba(0, 79, 163, 1) 100%);
        background: -webkit-gradient( linear, left top, right top, from(rgba(0, 79, 163, 1)), color-stop(50%, rgba(0, 177, 238, 1)), to(rgba(0, 79, 163, 1)));
        background: linear-gradient( 90deg, rgba(0, 79, 163, 1) 0%, rgba(0, 177, 238, 1) 50%, rgba(0, 79, 163, 1) 100%);
        width: 50%;
    }
    .ac-desktop-page .ac-logo-oreo .ac-btn.ac-sms-send-btn {
        width: max-content;
        border-radius: 28px;
        margin: 0 0.5vw;
        position: relative;
        top: 0.75vw;
    }
    .ac-desktop-page .ac-tel-input {
        display: flex;
        flex-direction: column;
    }
    .ac-desktop-page .ac-tel-input label {
        font-size: 1.2vw;
        margin-bottom: 0.5vw;
        margin-right: 1vw;
        font-family: "Spoiler-Regular";
    }
    .ac-desktop-page .ac-logo-oreo .ac-btn.ac-sms-send-btn .ac-sms-word {
        margin-right: 6px;
        margin-top: 1px;
    }
    .ac-desktop-page .ac-ends {
        width: 100%;
    }
    .ac-desktop-page .marquee {
        height: 5.5vw;
    }
    .ac-desktop-page .marquee-top {
        position: fixed;
        /* top: -2vw; */
        top: -2.4vw;
    }
    .ac-desktop-page .marquee-bottom {
        position: fixed;
        bottom: -0.6vw;
    }
    .ac-desktop-page .wrapper-verticaly {
        max-width: unset;
        overflow: hidden;
        transform: rotate(90deg);
        position: fixed;
        top: 90vw;
        left: -96vw;
        height: 4.5vw;
        width: 198%;
    }
    .ac-desktop-page .wrapper-verticaly-right {
        left: 0.5vw;
        height: 5vw;
    }
    .ac-desktop-page .ac-cover {
        height: 3.5vw;
    }
    .ac-desktop-page .ac-corner-wrapper {
        width: 100%;
        height: 0;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .ac-desktop-page .ac-corner {
        width: 1.1vw;
        background-color: #004fa3;
        height: 9vw;
        z-index: 9;
    }
    .ac-desktop-page .ac-corner-right {
        width: 2.1vw;
        position: relative;
        right: -0.9vw;
    }
    .ac-desktop-page .ac-bottom-cover .ac-corner-left {
        width: 1vw;
    }
    .ac-desktop-page .ac-bottom-cover .ac-corner-wrapper {
        top: -7px;
    }
    .ac-desktop-page span.ac-desktop-txt {
        font-family: "Spoiler-Regular";
        font-size: 1.45vw;
        text-align: center;
        margin-bottom: 1vw;
        display: block;
    }
    .ac-desktop-page span.ac-desktop-ttl {
        font-family: "SpoilerBlack";
        text-align: center;
        display: block;
        line-height: 0.8;
        font-size: 2.6vw;
        margin-top: -2vw;
        margin-bottom: 1vw;
    }
    .ac-desktop-page .ac-loader-wrapper .ac-logo-oreo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        position: relative;
        left: unset;
        top: unset;
        transform: unset;
    }
    .ac-desktop-page .ac-left-side {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ac-desktop-page .ac-left-side img {
        width: 85%;
        position: relative;
        right: -11%;
    }
    .ac-desktop-page .ac-bye {
        width: max-content;
        background-color: #fff;
        color: #0057c8;
        font-family: "SpoilerBlack";
        font-size: 2vw;
        border-radius: 8px;
        margin: auto;
        padding: 2% 5%;
        box-sizing: border-box;
        height: 2vw;
    }
    .ac-desktop-page .ac-sms-word {
        display: inline-block;
        font-size: 70%;
        font-family: "PlutoCondLight";
        justify-content: unset;
        align-items: unset;
        text-align: inherit;
    }
}