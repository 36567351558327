@font-face {
    font-display: swap;
    font-family: "AdobeHebrew-Regular";
    src: url(../fonts/AdobeHebrew-Regular.eot);
    src: url(../fonts/AdobeHebrew-Regular.eot) format("embedded-opentype"), url(../fonts/AdobeHebrew-Regular.woff) format("woff"), url(../fonts/AdobeHebrew-Regular.ttf) format("truetype"), url(../fonts/AdobeHebrew-Regular.svg#MyriadProRegular) format("svg");
    /*font-weight:normal;font-style:normal;*/
}

@font-face {
    font-display: swap;
    font-family: "Arial_Bold";
    src: url(../fonts/Arial_Bold.eot);
    src: url(../fonts/Arial_Bold.eot) format("embedded-opentype"), url(../fonts/Arial_Bold.woff) format("woff"), url(../fonts/Arial_Bold.ttf) format("truetype"), url(../fonts/Arial_Bold.svg) format("svg");
    /*font-weight:normal;font-style:normal;*/
}

@font-face {
    font-display: swap;
    font-family: "PlutoBold";
    src: url(../fonts/PlutoBold.eot);
    src: url(../fonts/PlutoBold.eot) format("embedded-opentype"), url(../fonts/PlutoBold.woff) format("woff"), url(../fonts/PlutoBold.ttf) format("truetype"), url(../fonts/PlutoBold.svg) format("svg");
    /*font-weight:normal;font-style:normal;*/
}

@font-face {
    font-display: swap;
    font-family: "PlutoCondLight";
    src: url(../fonts/PlutoCondLight.eot);
    src: url(../fonts/PlutoCondLight.eot) format("embedded-opentype"), url(../fonts/PlutoCondLight.woff) format("woff"), url(../fonts/PlutoCondLight.ttf) format("truetype"), url(../fonts/PlutoCondLight.svg) format("svg");
    /*font-weight:normal;font-style:normal;*/
}

@font-face {
    font-display: swap;
    font-family: "FbReformaNarrow-Regular";
    src: url(../fonts/FbReformaNarrow-Regular.eot);
    src: url(../fonts/FbReformaNarrow-Regular.eot) format("embedded-opentype"),
       url(../fonts/FbReformaNarrow-Regular.woff) format("woff"),
       url(../fonts/FbReformaNarrow-Regular.ttf) format("truetype"),
       url(../fonts/FbReformaNarrow-Regular.svg) format("svg");
    /*font-weight:normal;font-style:normal;*/
}
@font-face {
    font-display: swap;
    font-family: "FbReformaNarrowMedium";
    src: url(../fonts/FbReformaNarrowMedium.eot);
    src: url(../fonts/FbReformaNarrowMedium.eot) format("embedded-opentype"),
       url(../fonts/FbReformaNarrowMedium.woff) format("woff"),
       url(../fonts/FbReformaNarrowMedium.ttf) format("truetype"),
       url(../fonts/FbReformaNarrowMedium.svg) format("svg");
    /*font-weight:normal;font-style:normal;*/
}
@font-face {
    font-display: swap;
    font-family: "FbReformaNarrowBold";
    src: url(../fonts/FbReformaNarrowBold.eot);
    src: url(../fonts/FbReformaNarrowBold.eot) format("embedded-opentype"),
       url(../fonts/FbReformaNarrowBold.woff) format("woff"),
       url(../fonts/FbReformaNarrowBold.ttf) format("truetype"),
       url(../fonts/FbReformaNarrowBold.svg) format("svg");
    /*font-weight:normal;font-style:normal;*/
}
@font-face {
    font-display: swap;
    font-family: "Digital-7";
    src: url(../fonts/Digital-7.eot);
    src: url(../fonts/Digital-7.eot) format("embedded-opentype"),
       url(../fonts/Digital-7.woff) format("woff"),
       url(../fonts/Digital-7.ttf) format("truetype"),
       url(../fonts/Digital-7.svg) format("svg");
    /*font-weight:normal;font-style:normal;*/
}

@font-face {
    font-display: swap;
    font-family: "FbAhavaBold";
    src: url(../fonts/FbAhavaBold.ttf);
    src: url(../fonts/FbAhavaBold.ttf) format("truetype");
}

@font-face {
    font-display: swap;
    font-family: "FbSpoilerBold";
    src: url(../fonts/FbSpoilerBold.ttf);
    src: url(../fonts/FbSpoilerBold.ttf) format("truetype");
}

@font-face {
    font-display: swap;
    font-family: "FbSpoilerLight";
    src: url(../fonts/FbSpoilerLight.ttf);
    src: url(../fonts/FbSpoilerLight.ttf) format("truetype");
}

@font-face {
    font-display: swap;
    font-family: "FbSpoilerRegular";
    src: url(../fonts/FbSpoilerRegular.ttf);
    src: url(../fonts/FbSpoilerRegular.ttf) format("truetype");
}

@font-face {
    font-display: swap;
    font-family: "SpoilerBlack";
    src: url(../fonts/SpoilerBlack.ttf);
    src: url(../fonts/SpoilerBlack.ttf) format("truetype");
}

@font-face {
    font-display: swap;
    font-family: "Spoiler-Regular";
    src: url(../fonts/Spoiler-Regular.ttf);
    src: url(../fonts/Spoiler-Regular.ttf) format("truetype");
}

.ac-ahavabold {
    font-family: "FbAhavaBold";
}

.ac-spoilerbold {
    font-family: "FbSpoilerBold";
}

.ac-spoilerlight {
    font-family: "FbSpoilerLight";
}

.ac-spoilerregular {
    font-family: "FbSpoilerRegular";
}

html {
    background-color: #eee;
    font-family: "Lato", Calibri, Arial, sans-serif;
    font-size: 16px;
    color: #888;
}

body {
    position: absolute;
    width: 100%; 
    margin: 0px;
    padding: 0px;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    height: 100vh;
    color: #fff;
    direction: rtl;
    line-height: 1;
    font-size: 5vw;
    font-family: "FbReformaNarrow-Regular";
   
}

.ac-blue {
    color: #00b8f1;
}

.ac-dark-blue {
    color: #004b9d;
}

.ac-img-responive {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

p {
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
}

.a-canvas {
    height: 100% !important;
    position: relative;
    width: 100% !important;
}

#MAINWRAPPER {
    min-height: 100%;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding: 0;
    /* width: 70%; */
    /* page width */
    /* background-color: #39f; */
    /* border: 1px solid #959595; */
}

#WRAP {
    position: relative;
    display: block;
    margin: 0 auto;
}

.frame {
    /* height: 500px; */
    position: relative;
    background-color: #fff;
    display: block;
    /* border: 1px solid #959595; */
    /* border-radius: 4px; */
    text-align: center;
}

.frame a {
    display: block;
    width: 100%;
    height: 100%;
    color: #333;
}

.frame a:hover {
    display: block;
    color: #fff;
}

.title {
    display: block;
    position: absolute;
    overflow: hidden;
    top: 0;
    background-color: #ccc;
    padding: 3px 0;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #959595;
}

div.price {
    display: block;
    position: absolute;
    bottom: 1px;
    right: 0;
    height: 1.6em;
    width: 3em;
    background-color: #33ccff;
    border-radius: 5px;
    border: 2px solid #fff;
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
}

.ac-container {
    width: 90%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

header {
    padding: 19px 0 5px;
    z-index: 9;
    position: relative;
}

header .ac-container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 0px;
    color: #fff;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

header #ac-icon-nav {
    width: 48.07px;
    height: 48.07px;
    position: relative;
    right: -4px;
}

header #ac-icon-nav svg {
    width: 43px;
    height: 43px;
}

header #ac-share-page {
    width: 62px;
    height: 34px;
    position: relative;
    left: 0px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    border: unset;
    font-size: 21px;
    border-radius: unset;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
}

header #ac-share-page svg {
    width: 100%;
    height: 100%;
    margin-right: 0;
}

.ac-btn {
    font-family: "FbSpoilerBold";
    font-family: "FbReformaNarrowBold";
    background-color: #004FA3;
    color: #fff;
    border-radius: 42px;
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin: 0 auto;
    align-items: center;
    text-decoration: none;
    width: 89%;
    height: auto;
    padding: 3% 15%;
    border: 3px solid #fff;
}

.ac-btn.ac-btn-darkmode {
    background-color: #004b9d !important;
    color: #fff;
}

.ac-btn.ac-btn-darkmode .ac-inner-btn svg {
    fill: #fff;
}

.ac-btn.ac-btn-grey {
    background-color: #004b9d !important;
    border-color: #004b9d !important;
    color: #fff !important;
}

.ac-btn.ac-btn-grey .ac-inner-btn svg {
    fill: #fff !important;
}

.ac-btn .ac-inner-btn {
    display: flex;
    align-items: center;
    font-size: 230%;
    justify-content: center;
    width: 100%;
    font-family: "FbSpoilerBold";
    font-family: "FbReformaNarrowBold";
    color: #fff;
}

.ac-btn.ac-btn-goto-form .ac-inner-btn {
    font-size: 154%;
}
button.ac-btn.ac-btn-goto-form .ac-inner-btn {
    font-size: 226%; 
}
.ac-btn .ac-inner-btn span {
    position: relative;
    /* top: -3px; */
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrowMedium";
}

.ac-btn .ac-inner-btn svg {
    width: 11%;
    fill: #004fa3;
    margin-right: 10px;
}

nav {
    width: 293px;
    padding: 20px;
    background-color: #004fa3;
    position: fixed;
    top: 0;
    right: -200%;
    transition: right 0.3s;
    transition-timing-function: ease-out;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #00b2ef;
    background: radial-gradient(ellipse at center, #00b2ef 0%, #004fa3 51%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00b2ef', endColorstr='#004fa3', GradientType=1);
    z-index: 9;
}

nav.ac-opened {
    right: 0;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrow-Regular";
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 30px;
    font-weight: 100;
}

nav ul li {
    padding: 18px 0;
}

nav ul li:nth-child(2) {
    border-top: 0.5vw solid #00b1ee;
    border-bottom: 0.5vw solid #00b1ee;
}

nav ul li:nth-child(3) {
    border-bottom: 0.5vw solid #00b1ee;
}

nav ul li:nth-child(4) {
    border-bottom: 0.5vw solid #00b1ee;
}

nav ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    margin-top: 30px;
}

nav ul li a span {
    position: relative;
    top: -3px;
}

nav svg {
    position: relative;
    width: 32px;
    top: 0;
    height: 32px;
    right: 0;
    margin-left: 20px;
}

nav .ac-wrapper-close button {
    padding: 0;
    margin: 0;
}

nav .ac-wrapper-close button span {
    display: block;
    transform: rotate(45deg);
    font-size: 45px;
    position: relative;
    right: -4px;
}

@media (min-width: 600px) {
    body {
        max-width: unset;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

.a-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
}


/*
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.frame{
  animation: 1s ease-out 0s 1 slideInFromLeft;
} */

.ac-share-menu-popup.ac-closed {
    display: none;
}

.ac-share-menu-popup.ac-opened {
    display: block;
    width: 36vw;
    position: absolute;
    top: 15vw;
    left: 4vw;
    border: 0.7vw solid #fff;
    border-radius: 3vw;
    box-shadow: 1vw 1vw 3vw rgb(0 0 0 / 50%);
    background-color: #004fa3;
    padding: 2vw 0px;
}

.ac-share-menu-popup .ac-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ac-share-menu-popup .ac-related-arrow {
    width: 100%;
    height: 0;
    position: relative;
}

.ac-share-menu-popup .ac-related-arrow svg {
    width: 4vw;
    height: auto;
    position: absolute;
    left: 10px;
    top: -5.2vw;
}

.ac-share-menu-popup .ac-top {
    padding: 2vw 0;
    border-bottom: 0.5vw solid #00b1ee;
    width: 90%;
    text-align: center;
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrow-Regular";
}

.ac-share-menu-popup ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}

.ac-share-menu-popup ul a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ac-share-menu-popup ul button,
.ac-share-menu-popup ul a {
    width: 8vw;
    height: auto;
}

.ac-share-menu-popup ul button img {
    width: 100%;
}

.ac-share-menu-popup .ac-bottom {
    width: 100%;
    padding: 4vw 0;
}

p span.ac-subtitle {
    /* font-size: 4.1vh; */
    font-size: 126%;
    font-weight: 900;
    font-family: "FbSpoilerBold";
    font-family: "FbReformaNarrowBold";
    display: block;
    margin-bottom: -5vw;
}

.ac-btn.ac-start-game-btn {
    background-color: #004FA3;
    color: #fff;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin: 0 auto;
    align-items: center;
    text-decoration: none;
    height: auto;
    padding: 0 6%;
    width: 50%;
    border: 2px solid #fff;
    margin-top: 5vw;
}
.container-2 .ac-btn.ac-start-game-btn{
    margin-top:0
}
.ac-btn.ac-start-game-btn .ac-inner-btn {
    display: flex;
    align-items: center;
    font-size: 138%;
    font-weight: 900;
    /* color: #0057c8; */
    color:#fff;
    justify-content: center;
    padding: 1vw 0 2vw 0;
}

.ac-btn.ac-start-game-btn .ac-inner-btn span {
    position: relative;
    margin-right: 0;
    margin-top: 1%;
}

.ac-btn.ac-start-game-btn .ac-inner-btn svg {
    fill: #004fa3;
    width: 8%;
    margin-right: 8px;
    position: relative;
    top: 2px;
}

#ac-start-game .ac-close {
    visibility: hidden;
}

#ac-found-oreo.ac-wrapper-popup .ac-inner {
    background: transparent;
    width: 100%;
    border: 0;
    padding: 0;
}

#ac-found-oreo.ac-wrapper-popup .ac-inner .ac-content p {
    font-size: 108%;
    position: relative;
    margin: 0 auto 3%;
}

#ac-found-oreo.ac-wrapper-popup .ac-inner .ac-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ac-found-oreo.ac-wrapper-popup .ac-inner .ac-content .ac-btn {
    margin: 0vh auto;
    position: relative;
    width: 55%;
    padding: 2.5% 14% 3% 14%;
}
#ac-found-oreo.ac-wrapper-popup .ac-inner .ac-content button.ac-btn {
    margin: 0vh auto;
    position: relative;
    width: 83%;
    padding: 2.5% 14% 3% 14%;
}
#ac-found-oreo.ac-wrapper-popup .ac-inner .ac-content img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
}

#ac-found-oreo.ac-wrapper-popup .ac-inner .ac-content .ac-popuptitle {
    text-align: center;
    margin: 0 auto;
    display: block;
    color: #fff;
    position: relative;
    font-size: 300%;
    font-family: "FbReformaNarrowMedium";
    line-height: 0.8;
    margin-bottom: 0vw;
}

.ac-page-title {
    display: flex;
    text-align: center;
    margin: auto;
    font-size: 17vw;
    font-family: "FbReformaNarrowBold";
    width: max-content;
}

.ac-light-text {
    display: flex;
    text-align: center;
    margin: auto;
    font-size: 5.5vw;
    width: max-content;
    margin-bottom: 2vh;
}

.ac-pas-form {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60%;
    width: 50%;
}

.ac-pas-form input {
    height: 40px;
}

#ac-missed-oreo.ac-wrapper-popup p {
    font-size: 210%;
    font-family: "FbReformaNarrowBold";
    line-height: 0.8;
}

#ac-missed-oreo.ac-wrapper-popup .ac-btn {
    padding: 3% 1%;
}

/* #ac-missed-oreo.ac-wrapper-popup .ac-btn .ac-inner-btn span {
    font-size: 154%;
} */

#ac-missed-oreo.ac-wrapper-popup .ac-btn .ac-inner-btn {
    font-size: 128%;
}

#ac-missed-oreo.ac-wrapper-popup .ac-btn.ac-btn-grey {
    background-color: #fff !important;
}

#ac-missed-oreo.ac-wrapper-popup .ac-btn.ac-btn-grey .ac-inner-btn {
    color: #0057c8;
    font-size: 5.5vw;
}

#ac-missed-oreo.ac-wrapper-popup .ac-btn .ac-inner-btn svg {
    width: 9%;
    margin-right: 4px;
}

.ac-home-btn-navigator {
    width: 9vw;
    height: 9vw;
}
.ac-countdow .ac-overlay-countdow{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.88) 0%, rgba(0, 1, 2, 0.01) 100%);
}
.ac-countdow h1{
    font-family: "Digital-7";
    font-size: 62vw;
    color: #fff;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ac-text-box .ac-text{
    font-size: 140%;
    font-family: "FbReformaNarrowMedium";
    display: block;
    line-height: 1.1;
    text-align: center;
}