.ac-loader-wrapper {
    /* width: 100%; */
    height: 90vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
}

.ac-loader-wrapper .ac-logo-oreo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.ac-blue {
    color: #00b1ee;
    margin: auto 6px;
}

.ac-loader-wrapper img {
    width: 78%;
}

.ac-loader-wrapper p {
    font-family: FbSpoilerBold;
}

.ac-loader-wrapper span {
    /* font-size: 70%; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "PlutoBold";
}

.marquee p,
.marquee p span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ac-loader-wrapper {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00b1ee+0,004fa3+61 */
    background: #00b1ee;
    /* Old browsers */
    background: -moz-radial-gradient( center, ellipse cover, #00b1ee 0%, #004fa3 100%);
    /* FF3.6-15 */
    background: -webkit-radial-gradient( center, ellipse cover, #00b1ee 0%, #004fa3 61%);
    /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #00b1ee 0%, #004fa3 61%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00b1ee', endColorstr='#004fa3', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    width: 100%;
    height: 100vh;
    display: flex;
}

.wrapper {
    max-width: 100%;
    overflow: hidden;
}

.marquee {
    white-space: nowrap;
    /* overflow: hidden; */
    display: inline-block;
    animation: marquee 25s linear infinite;
    height: 40px;
    width: auto;
}

.marquee p {
    display: inline-block;
    font-size: 25px;
    font-size: 3.5vw;
}

.wrapper-verticaly {
    max-width: unset;
    overflow: hidden;
    transform: rotate(90deg);
    position: fixed;
    top: 90vw;
    left: -144vw;
    height: 22px;
    width: 300%;
}

.wrapper-verticaly-right {
    left: -56vw;
}

.ac-bottom-cover .marquee,
.wrapper-verticaly-left .marquee {
    white-space: nowrap;
    /* overflow: hidden; */
    display: inline-block;
    animation: marqueeOtherSide 25s linear infinite;
    height: 40px;
    width: auto;
}

@keyframes marquee {
    0% {
        transform: translate3d(0%, 0, 0);
    }
    100% {
        transform: translate3d(50%, 0, 0);
    }
}

@keyframes marqueeOtherSide {
    0% {
        transform: translate3d(50%, 0, 0);
    }
    100% {
        transform: translate3d(0%, 0, 0);
    }
}

.ac-loader-wrapper .ac-logo-oreo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.marquee-bottom {
    position: fixed;
    bottom: -6px;
}

.marquee-top {
    position: fixed;
    top: 10px;
}

.marquee-left {
    animation: unset;
    position: fixed;
    bottom: 40px;
}

.ac-cover {
    width: 100%;
    /* height: 47px; */
    /* 2021-07-01*/
    height: 35px;
    /* background: #0063B2;*/
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0074bf+1,0069b7+100 */
    background: #004fa3;
    /* Old browsers */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0074bf', endColorstr='#0069b7', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    position: fixed;
    /* top: 0; */
    top: -6px;
    top: -1.5vw;
}

.ac-bottom-cover {
    bottom: -5px;
    bottom: -1.5vw;
    top: unset;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0074bf+1,0069b7+100 */
    background: #004fa3;
    /* Old browsers */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0074bf', endColorstr='#0069b7', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.ac-corner-wrapper {
    width: 100%;
    height: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ac-corner {
    width: 4.8vw;
    background-color: #004fa3;
    height: 9vw;
    z-index: 9;
}

.ac-corner-right {
    width: 5.9vw;
    position: relative;
    right: -0.9vw;
}

@media (max-width: 330px) {
    .ac-corner {
        width: 5.5vw;
        height: 9.5vw;
    }
    .ac-corner-right {
        width: 5.2vw;
    }
}