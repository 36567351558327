.popup-root>div {
    position: fixed;
    inset: 0px;
    display: flex;
    z-index: 999;
    pointer-events: auto;
}

#mainOpening {
    /* margin-top: -50px;
    width: 100%;
    height: 100vh; */
    position: absolute;
    width: 100%;
    height: 100vh;
    margin-top: unset;
    top: 0;
    left: 0;
}

.ac-start-game {
    position: fixed;
    top: 0;
    background: grey;
    width: 100%;
    height: 100vh;
    z-index: 9;
}

.ac-start-game #ac-start-game .ac-img-wrapper-2 {
    width: 40%;
    margin: 8% auto 0;
}


/* .ac-start-game #ac-start-game .ac-cities {
    letter-spacing: 2.8px;
    font-family: "PlutoBold";
    margin-top: 1vh;
} */



.ac-start-game .ac-wrapper-popup .ac-btn {
    margin: 3vh auto;
    padding: 3% 6%;
}

.ac-start-game .ac-game-navigator {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    display: -webkit-flex;
    display: flex;
    background-color: rgb(0 79 163/63%);
    background: #00b2ef;
    background: -moz-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    background: -webkit-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    background: radial-gradient(ellipse at center, #00b2ef 0%, #004fa3 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00b2ef', endColorstr='#004fa3', GradientType=1);
    padding: 0px 10px;
    box-sizing: border-box;
    border-top: 2px solid #00000029;
}

.ac-start-game .ac-game-navigator .ac-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 97%;
    flex-direction: row-reverse;
}

.ac-start-game .ac-game-navigator .ac-container .ac-col {
    padding: 5px 0;
}

.ac-start-game .ac-game-navigator .ac-container .ac-col-2 {
    border-right: 1px solid #fff;
    padding-right: 18px;
}

.ac-start-game .ac-game-navigator .ac-container .ac-col-2 svg {
    width: 100%;
    height: 100%;
}

.ac-start-game .ac-game-navigator .ac-container p {
    /* font-family: sans; */
    color: #fff;
    font-size: 6vw;
    font-family: "PlutoBold";
}

.ac-start-game .ac-col-2 {
    position: relative;
}

.ac-start-game .ac-col-2 span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ac-start-game .ac-col-2 span.ac-city-name-1 {
    -webkit-text-stroke: 5px #fff;
}

.ac-start-game .ac-col-2 span.ac-city-name-2 {
    color: #004b9d;
    font-size: 6vw;
    -webkit-text-stroke: 1px #004b9d;
}

.ac-start-game .ac-col-1 {
    display: flex;
    width: 30vw;
    justify-content: space-between;
}

.ac-start-game .ac-col-1 span {
    border: 0.05vw solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 50%;
    width: 6vw;
    height: 6vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ac-start-game .ac-col-1 span span {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    margin: auto 3px;
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrow-Regular";
    font-size: 108%;
    top: -0.05rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    padding: 0 0 0.2rem;
}

.ac-start-game .ac-btn .ac-inner-btn span {
    top: 1px;
    /* margin-right: 15%;
    display: block; */
}

.ac-start-game .ac-wrapper-popup .ac-btn.ac-btn-yalla span {
    margin-right: 15%;
    display: block;
}

.ac-start-game .ac-col-1 span span.ac-active {
    border: 0.1vw solid #fff;
    background-color: #fff;
    color: #00b8f1;
}


/* .ac-start-game .ac-popup-title {
    position: relative;
    width: 100%;
    height: 16vw;
}

.ac-start-game .ac-popup-title span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "FbSpoilerBold";
    width: 100%;
    text-align: center;
}

.ac-start-game .ac-popup-title span.ac-title-name-1 {
    -webkit-text-stroke: 3vw #004b9d;
    color: #fff;
    font-size: 13vw;
}

.ac-start-game .ac-popup-title span.ac-title-name-2 {
    color: #fff;
    font-size: 13vw;
    -webkit-text-stroke: 0px #fff;
} */


/* .ac-start-game #ac-found-oreo .ac-popup-title {
    height: 26vw;
} */

.ac-start-game #ac-found-oreo p {
    font-size: 8vw;
}

.ac_imp_header_color {
    background-color: rgb(0 79 163 / 63%) !important;
    display: none;
}

.ac_banner_timer {
    font-size: 8vw;
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrow-Regular";
    width: 21vw;
    text-align: left;
}

.ac_hide_header {
    display: none;
}

.ac_hide_header {
    display: none;
}


.ac-start-game .ac-wrapper-popup .ac-btn.ac-btn-yalla svg {
    width: 8%;
}